import { isInIframe } from '@uc/compass-app-bridge';
import axios from 'axios';
import type { AxiosResponse, AxiosInstance } from 'axios';
import isObject from 'lodash/isObject';
import logger from 'src/logger';
import { getCompassAppToken } from 'src/utils/compass-app-token';
import { getToken } from 'src/utils/token';
import { API_V3_BASE_URL } from './urls';

const updateLatestReleaseMiddleware = (response?: AxiosResponse): void => {
  if (response?.headers?.['glide-release']) {
    window.Glide.LATEST_RELEASE = response.headers['glide-release'];
    if (
      response.status >= 500 &&
      window.Glide?.RELEASE !== window.Glide?.LATEST_RELEASE &&
      !isInIframe()
    ) {
      window.location.reload();
    }
  }
};

const redirectToEnterpriseEdition = (response?: AxiosResponse): boolean => {
  const header =
    window.Glide.CONSTANTS?.HTTP_HEADER_ENTERPRISE_REDIRECT?.toLowerCase();
  const enterpriseHost = response?.headers?.[header];
  if (enterpriseHost && response.status === 200) {
    const url = `https://${enterpriseHost}${window.location.pathname}${window.location.search}`;
    window.location.assign(url);
    return true;
  }
  return false;
};

export function create(baseUrl: string): AxiosInstance {
  const apiAxios = axios.create({
    baseURL: baseUrl,
    transformRequest: [
      function transformRequest(data) {
        return JSON.stringify(data);
      },
    ],
  });

  apiAxios.interceptors.request.use(async (config) => {
    if (config.baseURL === API_V3_BASE_URL) {
      config.headers['Content-Type'] = 'application/json';

      if (!config.headers.Authorization) {
        const compassAppToken = await getCompassAppToken();
        config.headers.Authorization = `Bearer ${compassAppToken}`;
      }

      return config;
    }
    if (config.method === 'post' || config.method === 'put') {
      config.headers['Content-Type'] = 'application/json';
    }

    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.headers['X-CSRFToken'] = window.csrfToken || '';

    let token: string;
    if (window.Glide?.IS_FE_BE_SPLIT) {
      token = await getToken();
    } else {
      token = window.Glide?.authBearer;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (window.Glide?.env !== 'production') {
      Object.entries(window.__e2ePatches ?? {}).forEach(([patch, value]) => {
        if (value) {
          config.headers[`Glide-E2e-Patch-${patch}`] = JSON.stringify(
            isObject(value) ? value : {}
          );
        }
      });
    }

    return config;
  });

  apiAxios.interceptors.response.use(
    (response) => {
      if (redirectToEnterpriseEdition(response)) {
        return new Promise(() => {
          // Returns a promise that never resovle to prevent api caller from running
        });
      }
      updateLatestReleaseMiddleware(response);
      return response.data;
    },
    (error) => {
      logger.error(error);
      updateLatestReleaseMiddleware(error.response);
      if (
        error.response?.status === 401 &&
        !window.location.pathname.includes('/auth/login')
      ) {
        window.location.replace('/auth/login');
      }
      if (error.response?.data?.error) {
        return Promise.reject(Object.assign(error, error.response.data.error));
      }
      return Promise.reject(error);
    }
  );
  return apiAxios;
}

window.axios = axios;
